.dashboard-tabs > div:not(.active) {
  display: none;
}

.dashboard-tabs .panel-header svg.fa-star {
  color: #d8b20b;
}

.container > .page-navs {
  margin-top: 4rem;
}

.card .form-control:disabled,
.card .form-control[readonly] {
  background-color: #fff !important;
}

#field-wrapper {
  width: 393px !important;
}

#field-wrapper label {
  width: 100%;
}

.ml-md-auto a i {
  font-size: 1.5rem;
}

.btn-border-secondary {
  border: 1px solid #fac71f !important;
}

.btn-border-secondary:hover {
  background: #fac71f !important;
}

.hlTfmo .credit-card-input {
  padding-top: 3px !important;
  border: 1px solid #ccc !important;
  position: absolute;
  width: 100%;
  font-size: 1rem;
  margin-top: 4px !important;
  line-height: 1.5;
  text-align: center;
}

a:not(.btn, .fc-event),
.footer a {
  cursor: pointer;
  color: #00aeef !important;
}

a:hover,
.footer a:hover {
  color: #236db8 !important;
}

.email-content-main .email-compose-fields a {
  color: gray !important;
}

.email-content .email-head a,
.email-content .email-sender a,
.email-content .email-body a {
  color: #2a2f5b !important;
}

.email-content .email-attachments a {
  color: #35b7be !important;
}

.email-content .email-list a {
  color: #2a2f5b !important;
  text-decoration: none;
}

.email-list-detail {
  width: 660px !important;
}

.email-list-item:hover {
  background: #f1f1f1 !important;
}

.sidebar-wrapper a {
  color: #575962 !important;
}

.sidebar-wrapper a:hover {
  color: black !important;
}

.text-gray a {
  color: gray !important;
}

.text-gray a:hover {
  color: gray !important;
}

.btn-danger {
  color: #fff !important;
}

.topbar-nav .nav-link {
  color: #fff !important;
}

.topbar-nav .nav-link:hover {
  color: #ccc !important;
}

.paging_simple_numbers a {
  color: #575962 !important;
}

.paging_simple_numbers a:hover {
  color: black !important;
}

.dashboard-section .h1,
.dashboard-section h1 {
  font-size: 1.725rem;
}

.dashboard-section h2 {
  font-size: 1.35rem;
}

.dashboard-section h3 {
  font-size: 1.1625rem;
}

.dashboard-section h4 {
  font-size: 1.0375rem;
}

.dashboard-section h5 {
  font-size: 1rem;
}

.dashboard-section h6 {
  font-size: 0.875rem;
}

.dashboard-section .text-info,
.dashboard-section .text-info a {
  color: #48abf7 !important;
}

.btn-icon.btn-xs {
  padding: inherit !important;
}

.card-title {
  margin-bottom: 0 !important;
}

.btn.btn-sm {
  padding: 7px 13px !important;
  font-size: 0.75rem !important;
}

.main-header .nav-search input::placeholder {
  color: #fff;
}

.card {
  box-shadow: none !important;
}

.table-sales table tr:first-child td {
  border: none;
}

.btn-icon.btn-xs i {
  position: relative;
  top: -7px;
}

.modal-footer a,
.action_links a {
  color: #f0c032 !important;
  padding-left: 5px;
}

.modal-footer a:hover,
.action_links a:hover {
  color: #efb909 !important;
  padding-left: 5px;
}

/* Agency Cards */
.row.row-projects {
  align-items: stretchu;
}

.row.row-projects > div {
  padding-bottom: 2rem;
}

.row.row-projects img {
  /* max-width: 120px; */
}

.row.row-projects .card {
  height: 100%;
}

.row.row-projects .card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.row.row-projects .card-body h3,
.row.row-projects .card-body h4,
.row.row-projects .card-body p {
  width: 100%;
}

.row.row-projects .card-body a,
.row.row-projects .card-body button {
  margin-top: auto;
}

.dashboard-tabs .search-result {
  .card {
    @apply p-3 transition-colors;
    border: 2px solid theme('colors.white') !important;
  }

  a {
    @apply flex flex-col;
  }

  strong.card-title {
    @apply text-lg;
  }

  .icon {
    @apply absolute opacity-0 right-0 bottom-0 transition-opacity;
  }

  .card:hover {
    @apply border-blue-600 !important;

    a {
      text-decoration: none !important;
    }
  }
}

/* Stats Screen Service Ratings */

#stats .star-ratings-sprite {
  background: url('../images/star-rating-sprite.png');
  font-size: 0;
  height: 21px;
  line-height: 0;
  overflow: hidden;
  text-indent: -999em;
  width: 110px;
  margin: 0;
}

#stats .star-ratings-sprite-rating {
  background: url('../images/star-rating-sprite.png');
  background-position: 0 100%;
  float: left;
  height: 21px;
  display: block;
}

@media screen and (max-width: 640px) {
  .dashboard-tabs .main-banner {
    display: none;
  }
}
